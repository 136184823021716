.countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 30px;
    font-family: 'Digital', sans-serif;
  }
  
  .countdown-item {
    text-align: center;
  }

  .countdown-item span {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    color: #fff !important;
    font-family: 'Inter script=latin rev=1', sans-serif !important;
  }

  .flip-card-countdown {
    perspective: 1000px;
    padding: 20px;
    background-color: #0254dc;
    font-size: 7rem;
    color: #fff;
    border-radius: 10px;
    width: 140px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .finish-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
  }

  .finish-container p {
    font-family: 'Inter script=latin rev=1', sans-serif !important;
    font-size: 40px;
    margin: 0;
    font-weight: 600;
  }

  .event-date {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 50px;
    color: #fff;
  }
  
  /* Additional styles for the digital font */
  .countdown-container {
    font-family: 'Digital-7', sans-serif;
  }
  
  @font-face {
    font-family: 'Digital-7';
    src: url('digital-7.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }



  /* Mobile styles */
@media (max-width: 768px) {
  .countdown-container {
    flex-direction: row;
    gap: 20px; /* Reduce the gap between countdown items */
    margin-top: 30px; /* Reduce the margin at the top */
  }

  .flip-card-countdown {
    font-size: 4rem; /* Decrease font size for mobile */
    width: 70px; /* Adjust width */
    height: 100px; /* Adjust height */
    padding: 15px; /* Adjust padding */
  }

  .event-date {
    font-size: 40px;
  }
}
  