.uprace_2022 .clients-say-section .clients-say-card .desc {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5
}

.uprace_2022.join_now .how-join-section .how-join-header .heading,
.uprace_2022 .news-section .news-header .heading,
.uprace_2022 .clients-say-section .heading,
.uprace_2022 .sponsor-section .sponsor-header .heading,
.uprace_2022 .non-profit-organ-section .non-profit-organ-header .heading,
.uprace_2022 .why-join-section .heading,
.uprace_2022 .about-section .about-header .heading {
    color: #e95819;
    font-size: 1.75rem;
    line-height: 2.5rem;
    font-weight: 600
}

@media(min-width: 768px) {

    .uprace_2022.join_now .how-join-section .how-join-header .heading,
    .uprace_2022 .news-section .news-header .heading,
    .uprace_2022 .clients-say-section .heading,
    .uprace_2022 .sponsor-section .sponsor-header .heading,
    .uprace_2022 .non-profit-organ-section .non-profit-organ-header .heading,
    .uprace_2022 .why-join-section .heading,
    .uprace_2022 .about-section .about-header .heading {
        font-size: 2.5rem;
        line-height: 3.25rem;
        margin-bottom: 1rem
    }
}

.uprace_2022 .card-hover {
    border: 0 solid #eceef3;
    box-shadow: 0 .25rem .5625rem -0.0625rem rgba(0, 0, 0, .03), 0 .275rem 1.25rem -0.0625rem rgba(0, 0, 0, .05) !important
}

.uprace_2022 .hero-section .bg-hero {
    
}

.uprace_2022 .hero-section .banner-img {
    border-radius: 0;
    object-fit: cover;
    -o-object-fit: cover;
    width: 100%;
}

@media(min-width: 1200px) {
    .uprace_2022 .hero-section .banner-img {
        
    }
}

@media(min-width: 1680px) {
    .uprace_2022 .hero-section .banner-img {
        max-width: 1280px;
    }
}

.uprace_2022 .hero-section .heroSwiper .swiper-button-next,
.uprace_2022 .hero-section .heroSwiper .swiper-button-prev {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    color: #677788;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #fff;
    box-shadow: 0 .1875rem .4375rem 0 rgba(140, 152, 164, .4);
    border-radius: 50%;
    transition: all .2s ease-in-out
}

@media(max-width: 767px) {

    .uprace_2022 .hero-section .heroSwiper .swiper-button-next,
    .uprace_2022 .hero-section .heroSwiper .swiper-button-prev {
        display: none
    }
}

.uprace_2022 .hero-section .heroSwiper .swiper-button-next:after,
.uprace_2022 .hero-section .heroSwiper .swiper-button-prev:after {
    font-size: 1rem
}

.uprace_2022 .hero-section .card-hero {
    align-items: center;
    border: 0;
    border-radius: 0
}

.uprace_2022 .hero-section .card-hero .card-img-overlay {
    display: flex;
    justify-content: center;
    align-items: flex-end
}

@media(min-width: 768px) {
    .uprace_2022 .hero-section .card-hero .card-img-overlay .btn-join {
        margin-bottom: 1rem
    }
}

.uprace_2022 .about-section {
    padding-top: 3.75rem
}

.uprace_2022 .about-section .about-header {
    margin-bottom: 3.25rem
}

.uprace_2022 .about-section .about-content-item {
    align-items: center;
    margin-bottom: 3rem
}

@media(min-width: 1200px) {
    .uprace_2022 .about-section .about-content-item {
        margin-bottom: 5rem
    }
}

.uprace_2022 .about-section .about-content-item .title {
    color: #314154;
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-weight: 600;
    margin-bottom: 0
}

@media(min-width: 786px) {
    .uprace_2022 .about-section .about-content-item .title {
        font-size: 2.25rem;
        line-height: 2.75rem
    }
}

.uprace_2022 .about-section .about-content-item .text {
    color: #314154
}

.uprace_2022 .why-join-section {
    position: relative;
    margin-top: 6rem
}

.uprace_2022 .why-join-section .title {
    color: #212b36
}

.uprace_2022 .why-join-section .bg-gradient-primary {
    background: linear-gradient(90deg, #0151D6 0%, #00A8F3 50%, #0254DC 100%) !important
}

@media(min-width: 768px) {
    .uprace_2022 .why-join-section .thumbnail {
        height: 416px
    }
}

.uprace_2022 .non-profit-organ-section {
    padding-top: 3.75rem
}

.uprace_2022 .non-profit-organ-section .non-profit-organ-header {
    margin-bottom: 3.25rem
}

.uprace_2022 .non-profit-organ-section .non-profit-organ-content .flip-card {
    height: 310px;
    perspective: 1000px
}

.uprace_2022 .non-profit-organ-section .non-profit-organ-content .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform .8s;
    transform-style: preserve-3d
}

.uprace_2022 .non-profit-organ-section .non-profit-organ-content .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg)
}

.uprace_2022 .non-profit-organ-section .non-profit-organ-content .flip-card:hover .btn-more .text {
    padding-right: 4px;
    min-width: fit-content
}

.uprace_2022 .non-profit-organ-section .non-profit-organ-content .flip-card-front,
.uprace_2022 .non-profit-organ-section .non-profit-organ-content .flip-card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.uprace_2022 .non-profit-organ-section .non-profit-organ-content .flip-card-back {
    transform: rotateY(180deg)
}

.uprace_2022 .non-profit-organ-section .non-profit-organ-content .link-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%
}

.uprace_2022 .non-profit-organ-section .non-profit-organ-content .img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.uprace_2022 .non-profit-organ-section .non-profit-organ-content .btn-more .text {
    max-width: 0;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transition: max-width .1s ease-in-out;
    -moz-transition: max-width .1s ease-in-out;
    -o-transition: max-width .1s ease-in-out;
    transition: max-width .1s linear
}

.uprace_2022 .non-profit-organ-section .non-profit-organ-content .btn-more:hover .text {
    padding-right: 4px;
    min-width: fit-content
}

.uprace_2022 .sponsor-section {
    padding-top: 3.75rem
}

.uprace_2022 .sponsor-section .sponsor-item {
    margin-bottom: 3rem
}

.uprace_2022 .sponsor-section .sponsor-item .sponsor-m-img {
    width: 125px;
    height: auto
}

.uprace_2022 .sponsor-section .subtitle {
    margin-bottom: 1rem
}

.uprace_2022 .annual-event-section {
    padding-top: 3.75rem
}

.uprace_2022 .clients-say-section {
    position: relative;
    margin-top: 6rem
}

.uprace_2022 .clients-say-section .clients-say-card {
    background: #f8fcff;
    border-color: #0254dc
}

.uprace_2022 .clients-say-section .clients-say-card .avatar-thumbnail {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: contain;
    -o-object-fit: contain
}

.uprace_2022 .clients-say-section .clients-say-card .name {
    color: #212b36;
    font-weight: 600
}

.uprace_2022 .clients-say-section .clients-say-card .job-title {
    color: #212b36;
    font-size: .75rem;
    display: block;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 2.25rem
}

.uprace_2022 .clients-say-section .clients-say-card .divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 1px;
    background-color: #dbdfe2;
    width: 100%
}

.uprace_2022 .clients-say-section .clients-say-card .desc {
    max-height: 7.5rem
}

.uprace_2022 .clients-say-section .swiper-pagination {
    position: relative;
    display: none;
    margin-top: 2.5rem
}

@media(min-width: 768px) {
    .uprace_2022 .clients-say-section .swiper-pagination {
        display: flex;
        justify-content: flex-start;
        margin-left: 1.5rem;
        align-items: center
    }
}

.uprace_2022 .clients-say-section .swiper-pagination-bullet {
    width: 2.5rem;
    height: .375rem;
    border-radius: .5rem;
    background-color: #727f8e
}

.uprace_2022 .clients-say-section .swiper-pagination-bullet-active {
    background-color: #0254dc
}

.uprace_2022 .news-section {
    padding-top: 3.75rem;
    margin-bottom: 3rem
}

@media(min-width: 768px) {
    .uprace_2022 .news-section {
        margin-bottom: 5rem
    }
}

.uprace_2022 .news-section .img-cover-left {
    max-height: 388px;
    width: 100%;
    object-fit: cover;
    -o-object-fit: cover
}

.uprace_2022.join_now .how-join-section {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem
}

.uprace_2022.join_now .how-join-section .how-join-header {
    margin-bottom: 2rem
}

.uprace_2022.join_now .nav-sponsor .nav-link {
    width: 100%;
    height: 100%;
    background: #eff2f6;
    color: #212b36;
    padding-top: 1rem;
    padding-bottom: 1rem
}

.uprace_2022.join_now .nav-sponsor .nav-link .icon {
    display: inline-block;
    background-size: cover;
    width: 48px;
    height: 48px
}

.uprace_2022.join_now .nav-sponsor .nav-link .icon-run {
    background-image: url("https://uprace2.vcdn.vn/web/uprace/images/2022/runner.svg")
}

.uprace_2022.join_now .nav-sponsor .nav-link .icon-sponsor {
    background-image: url("https://uprace2.vcdn.vn/web/uprace/images/2022/sponsor.svg")
}

.uprace_2022.join_now .nav-sponsor .nav-link .icon-donate {
    background-image: url("https://uprace2.vcdn.vn/web/uprace/images/2022/donate.svg")
}

.uprace_2022.join_now .nav-sponsor .nav-link.active {
    background: #0254dc;
    color: #fff
}

.uprace_2022.join_now .nav-sponsor .nav-link.active .icon {
    filter: brightness(0) invert(1)
}

.uprace_2022.join_now .tab-content-card .card-body {
    padding: 2rem 2.5rem
}

.uprace_2022.join_now .tab-content-card .icon {
    width: 64px
}

.uprace_2022.join_now .tab-content-card.donate .big-text {
    color: #e95819;
    font-size: 4rem
}

.uprace_2022.join_now .tab-content-card.donate .desc {
    font-size: 1.125rem
}