/* custom style ant modal */
.ant-modal-body {
    text-align: left;
}
.ant-modal-content {
    border-radius: 10px !important;
}
.ant-btn-primary {
    border-radius: 10px !important;
    font-weight: 600 !important;
    height: 40px !important;
    background-color: #0254DC !important;
    min-width: 100px;
}

/* ant drawer */
.ant-drawer {
    z-index: 9999;
}

/* ant modal */
.ant-modal-mask {
    z-index: 9998;
}
.ant-modal-wrap {
    z-index: 9999;
}

.ant-message {
    padding-top: 60px !important;
}