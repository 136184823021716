/* styles for recharts */
.recharts-default-legend {
    padding: 10px !important;
}
.recharts-cartesian-axis-tick-line {
    display: none !important;
}

/* styles for date-picker */
.react-datepicker-wrapper
{
    width: auto !important;
}