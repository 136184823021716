
.event-bg .company, .event-bg .school, .event-bg .fc {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 128px;
    border-radius: 15px;
    transition: all .3s ease 0s;
    font-family: 'Inter script=latin rev=1';
}

.event-bg .item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: absolute;
    width: 120px;
    top: 130px;
    left: calc(50% - 58px);
}
.event-bg .item .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94px;
    height: 94px;
    border-radius: 50%;
    overflow: hidden;
}
.event-bg .item .logo img{
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}
.event-bg .item .details {
    width: 100%;
    margin-top: 15px;
}
.event-bg .item .details .name, .event-bg .item .details .km  {
    line-height: 15px;
    font-family: "SVN-BigNoodleTitling";
    color: #9f3106;
    font-size: 20px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.event-bg .button-container {
    display: flex;
    width: 100%;
}

.event-bg .button-container .button-detail {
    width: 100%;
    height: 40px;
    font-weight: 600;
}

.event-bg img.img-logo {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
}

.event-bg .ant-progress-inner {
    height: 25px !important;
    background-image: linear-gradient(90deg, #ffbc11 74%, #f34f1b 109%) !important;
    position: relative;
    overflow: visible;
}

.event-bg .ant-progress-inner::before {
    content: url('../../assets/image/people-run.svg');
    position: absolute;
    right: 0;
    top: -40%;
    transform: translateY(-50%);
}
.event-bg .ant-progress-bg {
    height: 25px !important;
    background-image: linear-gradient(90deg, #43aff2, #0559d9) !important;
}

.event-bg .ant-progress-text {
    display: none;
}

.event-bg .ant-tabs-tab {
   padding: 0 !important; 
}
.event-bg .ant-tabs-content-holder {
    border-left: none!important;
}

.event-bg .ant-tabs-tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: 100%;
    height: 128px;
    border-radius: 15px;
    transition: all .3s ease 0s;
}

.event-bg .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    background: hsla(0, 0%, 84.7%, .2);
    cursor: default;
    color:  black !important;
    font-weight: 700;
}

.event-bg .w-full {
    width: 100% !important;
}

.event-bg .w-50 {
    width: 50% !important;
}

.event-bg .button-search {
    background: #9aa3b0!important;
    width: 80px;
    height: 40px;
    line-height: 17px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border:1px solid #9aa3b0;
}

.event-bg .input-search {
    background: hsla(0, 0%, 84.7%, .2)!important;
    width: 300px;
    height: 40px;
    line-height: 18px;
    padding: 11px 35px 11px 12px;
    font-size: 14px;
    border: 0;
    border-radius: 8px;
    color: black !important;
}
.event-bg .ant-table-row, .event-bg .ant-table-cell {
    font-family: 'Inter script=latin rev=1';
    font-size: 16px!important;
}
.event-bg th.ant-table-cell {
    color: #797979;
}
.event-bg .item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: absolute;
    width: 120px;
}

.event-bg .item.lv1 {
    top: 130px;
    left: calc(50% - 55px);
}
.event-bg .item.lv2 {
    top: 168px;
    left: calc(50% - 185px);
}
.event-bg .item.lv3 {
    top: 195px;
    left: calc(50% + 75px);
}
.event-bg .company::before {
    content: "\f1ad";
    color: #ffab00;
    font-size: 17px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;

}

.event-bg .school::before {
    content: "\f19d";
    color: #50b83c;
    font-size: 17px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;

}

.event-bg .fc::before {
    content: "\f8ff";
    color: #5656d8;
    font-size: 17px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;

}

