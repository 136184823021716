.header .navbar-nav .dropdown-toggle:after,
.fi {
    font-family: 'sans-serif' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face {
    font-family: 'Inter script=latin rev=1';
    font-style: oblique 0deg 10deg;
    font-weight: 100 900;
    font-display: block;
    src: url(../../../fonts/family/UcCo3FwrK3iLTcvvYwYL8g.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
@font-face {
    font-family: 'Inter script=latin rev=1';
    font-style: oblique 0deg 10deg;
    font-weight: 100 900;
    font-display: block;
    src: url(../../../fonts/family/UcCo3FwrK3iLTcvmYwYL8g.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
    font-family: 'Inter script=latin rev=1';
    font-style: oblique 0deg 10deg;
    font-weight: 100 900;
    font-display: block;
    src: url(../../../fonts/family/UcCo3FwrK3iLTcvuYwYL8g.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
@font-face {
    font-family: 'Inter script=latin rev=1';
    font-style: oblique 0deg 10deg;
    font-weight: 100 900;
    font-display: block;
    src: url(../../../fonts/family/UcCo3FwrK3iLTcvhYwYL8g.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
@font-face {
    font-family: 'Inter script=latin rev=1';
    font-style: oblique 0deg 10deg;
    font-weight: 100 900;
    font-display: block;
    src: url(../../../fonts/family/UcCo3FwrK3iLTcvtYwYL8g.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
        U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: 'Inter script=latin rev=1';
    font-style: oblique 0deg 10deg;
    font-weight: 100 900;
    font-display: block;
    src: url(../../../fonts/family/UcCo3FwrK3iLTcvsYwYL8g.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: 'Inter script=latin rev=1';
    font-style: oblique 0deg 10deg;
    font-weight: 100 900;
    font-display: block;
    src: url(../../../fonts/family/UcCo3FwrK3iLTcviYwY.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'sans-serif';
    src: url(../../../fonts/uprace.eot);
    src: url(../../../fonts/uprace.eot) format('embedded-opentype'),
        url(../../../fonts/uprace.ttf) format('truetype'),
        url(../../../fonts/uprace.woff) format('woff'),
        url(../../../fonts/uprace.svg) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
.header .navbar-nav .nav-link.login-link,
.link {
    color: #0254dc;
    text-decoration: none;
}
.header .navbar-nav .nav-link.login-link:hover,
.link:hover {
    color: #023b97;
    text-decoration: underline;
}
body {
    color: #314154 !important;
    font-family: 'Inter script=latin rev=1', sans-serif !important;
    font-weight: 400;
}
@media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1160px;
    }
}
.brand-logo,
.navbar-brand-item {
    width: 130px;
    height: 19px;
}
.btn {
    border-radius: 0.5rem;
}
.btn-primary {
    background-color: #0254dc;
    border-color: #0254dc;
}
.btn-primary:hover {
    background-color: #023b97;
    border-color: #023b97;
}
.btn-outlibe-primary {
    background-color: #fff;
    border-color: #0254dc;
    color: #0254dc;
}
.btn-outlibe-primary:hover {
    background-color: #0254dc;
    color: #fff;
}
.link-ink-basic {
    color: #212b36;
    text-decoration: none;
}
.link-ink-basic:hover {
    color: #0254dc;
}
.text-primary {
    color: #0254dc !important;
}
.bg-dark {
    background: #314154 !important;
}
.google-play {
    width: 135px;
    height: 40px;
}
.app-store {
    width: 135px;
    height: 40px;
}
.btn-pulse-hover:hover {
    animation: pulse;
    animation-duration: 800ms;
}
.modal-content {
    border: none;
    border-radius: 0.5rem;
}
.modal-backdrop {
    background: #212b36;
}
.modal-backdrop.show {
    opacity: 0.4;
}
.card {
    border-radius: 0.5rem;
}
.fw-500 {
    font-weight: 500 !important;
}
.zi-1 {
    z-index: 1;
}
.zi-2 {
    z-index: 2;
}
.zi-5 {
    z-index: 5;
}
.zi-6 {
    z-index: 6;
}
.py-6 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}
.py-7 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.py-8 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
}
.opacity-10 {
    opacity: 0.1 !important;
}
.header {
    background: #fff;
    border: 1px solid #dbdfe2;
}
.header .navbar-toggler-icon {
    background-image: url('https://uprace2.vcdn.vn/web/uprace/icons/menu.svg');
}
.header .navbar-nav {
    flex-wrap: wrap;
}
@media (max-width: 767px) {
    .header .navbar-nav {
        padding-top: 2.5rem;
        height: 100%;
    }
    .header .navbar-nav .login-link {
        background-color: #fff;
        border-color: #0254dc;
        color: #0254dc;
    }
    .header .navbar-nav .login-link:hover {
        background-color: #0254dc;
        color: #fff;
    }
    .header .navbar-nav .dropdown-avatar,
    .header .navbar-nav .dropdown-language {
        position: relative;
    }
    .header .navbar-nav .dropdown-avatar .dropdown-menu,
    .header .navbar-nav .dropdown-language .dropdown-menu {
        position: absolute;
        top: -2.5rem;
        left: 50%;
        transform: translate(-50%, -50%) !important;
    }
}
.header .navbar-nav .nav-item {
    padding: 0.5rem 0.75rem;
}
.header .navbar-nav .nav-item .nav-link.active {
    color: #0254dc;
}
.header .navbar-nav .nav-link {
    color: #042052;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.625rem;
}
@media (min-width: 768px) {
    .header .navbar-nav .nav-link {
        color: #314154;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
    }
}
.header .navbar-nav .nav-link.login-link {
    width: -moz-fit-content;
    width: fit-content;
}
@media (min-width: 768px) {
    .header .navbar-nav .nav-link.login-link {
        text-align: end;
        padding-right: 0;
    }
}
.header .navbar-nav .dropdown-toggle:after {
    border: 0;
    content: '';
    color: #727f8e;
    font-size: 0.375rem;
    margin-left: 0.75em;
    vertical-align: middle;
}
.header .navbar-nav .dropdown-menu {
    border: 1px solid #e7eaec;
    min-width: 5rem;
}
.header .navbar-nav .dropdown-menu .dropdown-item-img {
    color: #314154;
}
.header .navbar-nav .dropdown-menu .dropdown-item-img-img {
    width: 20px;
    height: 15px;
}
.header .navbar-nav .dropdown-avatar .dropdown-toggle {
    text-decoration: none;
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
}
@media (min-width: 768px) {
    .header .navbar-nav .dropdown-avatar .dropdown-toggle {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}
.header .navbar-nav .dropdown-avatar img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.header .navbar-nav .btn-download-app,
.header .navbar-nav .login-link {
    width: 100%;
    height: 48px;
    line-height: 1.5rem;
    padding: 0.625rem 1rem;
}
@media (min-width: 768px) {
    .header .navbar-nav .btn-download-app,
    .header .navbar-nav .login-link {
        width: 132px;
        height: 40px;
        padding: 0.5rem;
    }
}
.header .offcanvas.show {
    height: 100vmax;
    min-height: 100vh;
}
.header .offcanvas-body {
    text-align: center;
}
@media (min-width: 768px) {
    .header .offcanvas-body {
        justify-content: flex-end;
    }
}
.bg-blue-blur {
    background: rgba(221, 234, 255, 0.3);
    backdrop-filter: blur(30px);
}
.footer {
    background: #212b36;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 400;
}
.footer .footer-brand {
    width: 160px;
    margin-bottom: 2rem;
}
.footer .widget-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer .widget-list-item {
    margin-bottom: 0.75rem;
}
@media (max-width: 767px) {
    .footer .widget-list-item {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}
@media (min-width: 768px) {
    .footer .widget-list-item {
        margin-bottom: 1.5rem;
    }
}
.footer .widget-list-link {
    color: #fff;
    text-decoration: none;
}
.footer .widget-list-link:hover {
    text-decoration: underline;
}
.footer .widget-title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
@media (min-width: 768px) {
    .footer .widget-title {
        margin-top: 0;
    }
}
@media (min-width: 992px) {
    .py-lg-8 {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
    }
    .ms-lg-10 {
        margin-left: 6rem !important;
    }
    .mt-lg-10 {
        margin-top: 6rem !important;
    }
}
.text-truncate-3 {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-height: 4.5rem;
}
.h-250 {
    height: 250px !important;
}
