.ant-modal-body {
    text-align: left;
}
.ant-modal-content {
    border-radius: 10px !important;
}
.ant-btn-primary {
    border-radius: 10px !important;
    font-weight: 600 !important;
    height: 40px !important;
    background-color: #0254DC !important;
}