.header {
  background: #ffffff;
}

.contact-section, .partner-section, .contact-growth-stat-section {
  background: #ffffff;
}
.contact-section .dash, .partner-section .dash, .contact-growth-stat-section .dash {
  background-color: #2E54E6;
  border-radius: 0.313rem;
  height: 0.375rem;
  width: 3.75rem;
  margin: 0 auto 0.75rem;
}
.contact-section .title, .partner-section .title, .contact-growth-stat-section .title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.125rem;
  color: #000000;
}
.contact-section .desc, .partner-section .desc, .contact-growth-stat-section .desc {
  font-size: 0.875rem;
  color: #212B36;
}
@media (min-width: 768px) {
  .contact-section .title, .partner-section .title, .contact-growth-stat-section .title {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  .contact-section .desc, .partner-section .desc, .contact-growth-stat-section .desc {
    font-size: 1rem;
  }
}

.contact-hero-section {
  background: #ffffff;
}
.contact-hero-section .banner {
  position: relative;
  display: inline-block;
  width: 100%;
  max-height: 650px;
  padding-left: 0;
}
@media (max-width: 767px) {
  .contact-hero-section .banner {
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  .contact-hero-section .banner {
    padding-left: 40px;
  }
}
.contact-hero-section .banner-img {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  max-width: 100%;
  height: auto;
}
.contact-hero-section .typed-cursor {
  color: #0254DC;
  font-weight: 400;
}

.contact-us-form-wrap {
  margin-top: -4rem;
  margin-bottom: 3.75rem;
}
.contact-us-form-wrap .card {
  color: #212B36;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  border: 0;
  border-radius: 0.625rem;
}
.contact-us-form-wrap .card-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.125rem;
}
.contact-us-form-wrap .card-subtitle {
  font-size: 1rem;
  font-weight: 600;
}
.contact-us-form-wrap .card-text {
  font-size: 0.875rem;
}
.contact-us-form-wrap .card .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1;
  /* Firefox */
}
.contact-us-form-wrap .card .form-control {
  background: #F4F6F8;
  border: 0;
  border-radius: 0.313rem;
}
.contact-us-form-wrap .card .form-control::-webkit-input-placeholder {
  color: #919EAB;
  font-size: 0.875rem;
}
.contact-us-form-wrap .card .form-control:-moz-placeholder {
  color: #919EAB;
  font-size: 0.875rem;
}
.contact-us-form-wrap .card .form-control::-moz-placeholder {
  color: #919EAB;
  font-size: 0.875rem;
}
.contact-us-form-wrap .card .form-control:-ms-input-placeholder {
  color: #919EAB;
  font-size: 0.875rem;
}
.contact-us-form-wrap .card .form-control.is-invalid, .contact-us-form-wrap .card .form-control .was-validated .form-control:invalid {
  border: 1px solid #DE3618;
}
@media (min-width: 768px) {
  .contact-us-form-wrap .card {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    margin-bottom: 0;
    max-width: 490px;
  }
  .contact-us-form-wrap .card .card-body {
    padding: 2rem;
  }
  .contact-us-form-wrap .card .card-title {
    font-size: 2rem;
    line-height: 2.75rem;
  }
  .contact-us-form-wrap .card .card-subtitle {
    font-size: 1.125rem;
  }
}

.contact-growth-stat-section {
  padding-bottom: 3.75rem;
}
.contact-growth-stat-section .growth-stat-bg-top .bg-img, .contact-growth-stat-section .growth-stat-bg-bottom .bg-img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  -o-object-fit: contain;
}
.contact-growth-stat-section .growth-stat-bg-top .bg-img__sm, .contact-growth-stat-section .growth-stat-bg-bottom .bg-img__sm {
  display: block;
}
.contact-growth-stat-section .growth-stat-bg-top .bg-img__md, .contact-growth-stat-section .growth-stat-bg-bottom .bg-img__md {
  display: none;
}
.contact-growth-stat-section .growth-stat-bg-top .bg-img__xxl, .contact-growth-stat-section .growth-stat-bg-bottom .bg-img__xxl {
  display: none;
}
@media (min-width: 768px) {
  .contact-growth-stat-section .growth-stat-bg-top .bg-img__sm, .contact-growth-stat-section .growth-stat-bg-bottom .bg-img__sm {
    display: none;
  }
  .contact-growth-stat-section .growth-stat-bg-top .bg-img__md, .contact-growth-stat-section .growth-stat-bg-bottom .bg-img__md {
    display: block;
  }
}
@media (min-width: 1400px) {
  .contact-growth-stat-section .growth-stat-bg-top .bg-img__md, .contact-growth-stat-section .growth-stat-bg-bottom .bg-img__md {
    display: none;
  }
  .contact-growth-stat-section .growth-stat-bg-top .bg-img__xxl, .contact-growth-stat-section .growth-stat-bg-bottom .bg-img__xxl {
    display: block;
  }
}
.contact-growth-stat-section .growth-stat-bg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (min-width: 768px) {
  .contact-growth-stat-section .growth-stat-bg-bottom {
    bottom: -150px;
  }
}
@media (min-width: 1400px) {
  .contact-growth-stat-section .growth-stat-bg-bottom {
    bottom: -300px;
  }
}
.contact-growth-stat-section .growth-stat-bg-top {
  position: absolute;
  top: 0;
  right: 0;
}
.contact-growth-stat-section .heading-block {
  padding-top: 3.75rem;
  padding-bottom: 5rem;
}
.contact-growth-stat-section .growth-stat-block {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.contact-growth-stat-section .growth-stat-block:not(:last-child) {
  margin-bottom: 3.125rem;
}
@media (min-width: 786px) {
  .contact-growth-stat-section .growth-stat-block {
    margin-bottom: 0;
    text-align: left;
  }
}
.contact-growth-stat-section .growth-stat-item .stat {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.75rem;
  color: #2E54E6;
}
@media (min-width: 768px) {
  .contact-growth-stat-section .growth-stat-item .stat {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}
.contact-growth-stat-section .growth-stat-item .desc {
  max-width: 205px;
  color: #000000;
}
.contact-growth-stat-section .growth-stat-swiper .swiper {
  width: 100%;
  height: 100%;
}
.contact-growth-stat-section .growth-stat-swiper .swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.contact-growth-stat-section .growth-stat-swiper__img {
  display: block;
  width: 100%;
  max-width: 165px;
  height: auto;
  object-fit: contain;
  -o-object-fit: contain;
  border-radius: 1.75rem;
  padding: 5px 13px 12px 11px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .contact-growth-stat-section .growth-stat-swiper__img {
    max-width: 189px;
  }
}
@media (min-width: 992px) {
  .contact-growth-stat-section .growth-stat-swiper__img {
    max-width: 250px;
  }
}
.contact-growth-stat-section .phone-frame {
  position: absolute;
}
.contact-growth-stat-section .phone-frame.iphone {
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
  z-index: 5;
}
@media (max-width: 413px) {
  .contact-growth-stat-section .phone-frame.iphone {
    width: 180px;
  }
}
.contact-growth-stat-section .phone-frame.runner {
  top: 91%;
  left: calc(100% - 85px);
  transform: translate(-50%, -50%);
  z-index: 6;
  width: 200px;
}
@media (min-width: 414px) {
  .contact-growth-stat-section .phone-frame.runner {
    left: calc(100% - 105px);
  }
}
@media (min-width: 768px) {
  .contact-growth-stat-section .phone-frame.runner {
    top: 92%;
    left: calc(100% - 30px);
  }
}
.contact-growth-stat-section .phone-frame.runner .img-fluid {
  width: 83px;
  height: 89px;
}
@media (min-width: 768px) {
  .contact-growth-stat-section .phone-frame.runner .img-fluid {
    width: 135px;
    height: 143px;
  }
}
.contact-growth-stat-section .phone-frame__img {
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}
.contact-growth-stat-section .mb-growth-stat-item {
  margin-bottom: 3.125rem;
}
@media (min-width: 768px) {
  .contact-growth-stat-section .mb-growth-stat-item {
    margin-bottom: 7.875rem;
  }
}
.contact-growth-stat-section .bg-phone-frame {
  position: absolute;
  top: 35%;
  left: 0;
  width: 100%;
  height: 80%;
  background-image: url("https://uprace2.vcdn.vn/web/uprace/contact-us/phone-frame-bg.png");
  background-size: cover;
  background-position: bottom;
}
@media (min-width: 768px) {
  .contact-growth-stat-section .bg-phone-frame {
    top: 15%;
    height: 85%;
    background-size: 100% 100%;
  }
}

.partner-section .partner-container {
  padding-top: 7rem;
}
.partner-section .swiper {
  margin-bottom: 4rem;
}
.partner-section .swiper-slide {
  width: 128px;
  height: 128px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (min-width: 768px) {
  .partner-section .swiper-slide {
    width: 168px;
    height: 152px;
  }
}
.partner-section .partner-swiper-img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
  -o-object-fit: cover;
}

.contact-section {
  background-image: url("https://uprace2.vcdn.vn/web/uprace/contact-us/contact-section-bg.png");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 310px;
}
@media (min-width: 1919px) {
  .contact-section {
    background-size: contain;
  }
}
@media (min-width: 768px) {
  .contact-section-block {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
}
.contact-section-block .col {
  text-align: center;
}
@media (min-width: 768px) {
  .contact-section-block .col {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .contact-section-block .col-desc {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.contact-section-block .card {
  border: 0;
  border-radius: 1.25rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
}
.contact-section-block .card-img {
  border-radius: 1.25rem;
}

.download-app-section {
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  display: flex;
  flex-wrap: wrap;
  padding-top: 3rem;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.download-app-section .left {
  padding-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .download-app-section {
    text-align: left;
    flex-direction: row;
  }
  .download-app-section .left {
    padding-bottom: 0;
    padding-right: 6rem;
  }
}
.download-app-section .heading {
  color: #000000;
  font-size: 1.75rem;
  font-weight: 600;
}
@media (min-width: 768px) {
  .download-app-section .heading {
    font-size: 2.5rem;
    line-height: 3.75rem;
  }
}
.download-app-section .desc {
  color: #314154;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.download-app-section .qr-code {
  width: 96px;
  height: 96px;
}
.download-app-section .illustration-thumbnail {
  width: 400px;
  height: 350px;
}

@media (min-width: 768px) {
  .contact-section .btn-contact {
    min-width: 132px;
  }
}

/*# sourceMappingURL=contact-us.css.map */
