.landing_page .news-section .news-card .card-body .desc {
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.landing_page .clients-say-section .clients-say-card .desc {
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.landing_page .news-section .swiper-controls .btn-next:after, .landing_page .news-section .swiper-controls .btn-prev:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "sans-serif" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  background-image: url("https://uprace2.vcdn.vn/web/uprace/landing/hero-cover-bg.png");
}

.landing_page .hero-section {
  backdrop-filter: blur(30px);
  min-height: 540px;
  background-image: url("https://uprace2.vcdn.vn/web/uprace/landing/hero-lb-bg-sm.svg"), url("https://uprace2.vcdn.vn/web/uprace/landing/hero-cover-bg-sm.png");
  background-position: left bottom, center;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;
}
@media (min-width: 768px) {
  .landing_page .hero-section {
    background-image: url("https://uprace2.vcdn.vn/web/uprace/landing/hero-lb-bg-md.svg"), url("https://uprace2.vcdn.vn/web/uprace/landing/hero-rb-bg-md.svg"), url("https://uprace2.vcdn.vn/web/uprace/landing/hero-cover-bg.png");
    background-position: left bottom, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto, cover;
  }
}
@media (min-width: 1400px) {
  .landing_page .hero-section {
    background-image: url("https://uprace2.vcdn.vn/web/uprace/landing/hero-lb-bg-xxl.svg"), url("https://uprace2.vcdn.vn/web/uprace/landing/hero-rb-bg-xxl.svg"), url("https://uprace2.vcdn.vn/web/uprace/landing/hero-cover-bg.png");
  }
}
.landing_page .hero-section .heading {
  font-size: 2.125rem;
  font-weight: 700;
  color: #000000;
  line-height: 3rem;
  margin-top: 5rem;
}
@media (min-width: 768px) {
  .landing_page .hero-section .heading {
    margin-top: 8rem;
    font-size: 2.5rem;
    line-height: 4rem;
  }
}
@media (min-width: 1200px) {
  .landing_page .hero-section .heading {
    margin-top: 8rem;
    font-size: 3.125rem;
    line-height: 4.375rem;
  }
}
.landing_page .hero-section .desc {
  color: #727F8E;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .landing_page .hero-section .desc {
    margin-bottom: 2.5rem;
  }
}
.landing_page .hero-section .btn-pulse-hover:hover {
  animation: pulse;
  animation-duration: 1s;
}
.landing_page .hero-section .more {
  margin-top: 8rem;
  margin-bottom: 3.5rem;
}
@media (min-width: 768px) {
  .landing_page .hero-section .more {
    margin-top: 5.5rem;
  }
}
.landing_page .hero-section .more .fi {
  line-height: 6px;
}
.landing_page .hero-section .more .fi-down-sm:before {
  content: "\e903";
  color: #314154;
  font-size: 0.5rem;
}
.landing_page .hero-section .more .fi-down-lg:before {
  content: "\e902";
  color: #314154;
  font-size: 0.75rem;
}
.landing_page .hero-section .download-app-img {
  max-width: 135px;
}
.landing_page .hero-section .illustrations .illustration-item {
  position: absolute;
}
.landing_page .hero-section .illustrations .illustration-top {
  top: 3.5rem;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 97px;
  height: 42px;
}
.landing_page .hero-section .illustrations .illustration-left {
  top: 22rem;
  left: 10%;
  width: 88px;
  height: 75px;
}
.landing_page .hero-section .illustrations .illustration-right {
  top: 24rem;
  right: 10%;
  width: 85px;
  height: 74px;
}
@media (min-width: 360px) {
  .landing_page .hero-section .illustrations .illustration-left {
    top: 19rem;
  }
  .landing_page .hero-section .illustrations .illustration-right {
    top: 20rem;
  }
}
@media (min-width: 768px) {
  .landing_page .hero-section .illustrations .illustration-left {
    top: 12rem;
    left: 0;
  }
  .landing_page .hero-section .illustrations .illustration-right {
    top: 14rem;
    right: 0;
  }
}
@media (min-width: 992px) {
  .landing_page .hero-section .illustrations .illustration-top {
    top: 15%;
    width: 147px;
    height: 64px;
  }
  .landing_page .hero-section .illustrations .illustration-left {
    top: 205px;
    left: 10%;
    width: 138px;
    height: 117px;
  }
  .landing_page .hero-section .illustrations .illustration-right {
    top: 230px;
    right: 10%;
    width: 135px;
    height: 117px;
  }
}
.landing_page .hero-section .illustrations .cycle {
  bottom: -5.5rem;
  left: 1rem;
  width: 88px;
  height: 74px;
}
.landing_page .hero-section .illustrations .run {
  bottom: -6.5rem;
  right: 1rem;
  width: 85px;
  height: 74px;
}
@media (min-width: 768px) {
  .landing_page .hero-section .illustrations .cycle {
    top: 50%;
    left: -6rem;
    width: 100px;
    height: 79px;
    transform: translateY(-50%) !important;
  }
  .landing_page .hero-section .illustrations .run {
    top: 60%;
    right: -6rem;
    width: 100px;
    height: 79px;
    transform: translateY(-50%) !important;
  }
}
@media (min-width: 992px) {
  .landing_page .hero-section .illustrations .cycle {
    top: 50%;
    left: -6.875rem;
    width: 138px;
    height: 117px;
    transform: translateY(-50%) !important;
  }
  .landing_page .hero-section .illustrations .run {
    top: 60%;
    right: -6.875rem;
    width: 135px;
    height: 117px;
    transform: translateY(-50%) !important;
  }
}
.landing_page .hero-section .illustrations .circle {
  top: -10px;
  right: 4px;
  width: 12px;
  height: 12px;
}
.landing_page .hero-section .illustrations .square {
  top: -20px;
  right: 25px;
  width: 10px;
  height: 10px;
}
.landing_page .hero-section .illustrations .triangle {
  top: -32px;
  right: 0;
  width: 16px;
  height: 16px;
}
@media (min-width: 768px) {
  .landing_page .hero-section .illustrations .square {
    top: -15px;
    right: 0;
    width: 16px;
    height: 16px;
  }
  .landing_page .hero-section .illustrations .circle {
    top: -5px;
    right: -25px;
    width: 20px;
    height: 20px;
  }
  .landing_page .hero-section .illustrations .triangle {
    top: -40px;
    right: -30px;
    width: 27px;
    height: 31px;
  }
}
@media (min-width: 992px) {
  .landing_page .hero-section .illustrations .square {
    top: -14px;
    right: 35px;
  }
  .landing_page .hero-section .illustrations .circle {
    top: 0;
    right: 5px;
  }
  .landing_page .hero-section .illustrations .triangle {
    top: -40px;
    right: 0;
  }
}
.landing_page .btn-download-app {
  width: 132px;
  height: 40px;
}
.landing_page .features-section {
  padding-top: 3.75rem;
}
.landing_page .features-section .features-header {
  margin-bottom: 3.25rem;
}
.landing_page .features-section .features-header .title {
  font-weight: 600;
  color: #000000;
  line-height: 3.75rem;
  margin-bottom: 1rem;
  font-size: 1.75rem;
}
@media (min-width: 768px) {
  .landing_page .features-section .features-header .title {
    font-size: 2.5rem;
  }
}
.landing_page .features-section .features-content-item {
  align-items: center;
  margin-bottom: 3rem;
}
@media (min-width: 1200px) {
  .landing_page .features-section .features-content-item {
    margin-bottom: 5rem;
  }
  .landing_page .features-section .features-content-item .desc-right {
    padding-left: 3.25rem;
  }
}
.landing_page .features-section .features-content .illustration-item {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
}
@media (min-width: 1200px) {
  .landing_page .features-section .features-content .illustration-item {
    width: 568px;
    height: 568px;
  }
}
.landing_page .features-section .features-content .title {
  font-weight: 600;
  color: #314154;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
@media (min-width: 786px) {
  .landing_page .features-section .features-content .title {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}
.landing_page .features-section .features-content .widget-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.landing_page .features-section .features-content .widget-list-item {
  display: inline-flex;
}
.landing_page .features-section .features-content .widget-list-item:not(:last-child) {
  margin-bottom: 1rem;
}
.landing_page .features-section .features-content .widget-list-item .text {
  color: #314154;
  font-size: 0.875rem;
  font-weight: 500;
}
.landing_page .features-section .features-content .widget-list-item .fi-check {
  margin-right: 0.5rem;
}
.landing_page .features-section .features-content .widget-list-item .fi-check:before {
  content: "\e901";
  color: #0254DC;
  font-size: 0.75rem;
}
.landing_page .annual-event-section {
  margin-bottom: 1.5rem;
}
.landing_page .annual-event-section .card {
  color: #ffffff;
  border-radius: 0.5rem;
  border: 0;
  min-height: 515px;
  background-image: url("https://uprace2.vcdn.vn/web/uprace/landing/anual-abstract-sm.svg"), url("https://uprace2.vcdn.vn/web/uprace/landing/anual-bg-sm.svg");
  background-position: left top, left top;
  background-size: auto, cover;
  background-repeat: no-repeat;
}
.landing_page .annual-event-section .card .card-body {
  padding: 1.5rem;
}
@media (min-width: 768px) {
  .landing_page .annual-event-section .card {
    min-height: auto;
    background-image: url("https://uprace2.vcdn.vn/web/uprace/landing/anual-abstract-rb-md.svg"), url("https://uprace2.vcdn.vn/web/uprace/landing/anual-abstract-lb-md.svg"), linear-gradient(337.39deg, #0151D6 31.49%, #00A8F3 218.64%);
    background-position: right bottom, left bottom, left top;
    background-size: auto 100%, auto, 100%;
    background-repeat: no-repeat, no-repeat, repeat;
  }
  .landing_page .annual-event-section .card .card-body {
    padding: 3rem;
  }
}
.landing_page .annual-event-section .heading {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .landing_page .annual-event-section .heading {
    font-size: 2.5rem;
    line-height: 3.25rem;
    color: #fff;
  }
}
.landing_page .news-section .news-card {
  padding: 0.75rem 0.75rem 1.5rem;
  box-shadow: 0 2px 12px rgba(91, 102, 120, 0.08);
  border-radius: 0.5rem;
  border: none;
  width: 100%;
}
.landing_page .news-section .news-card .card-body {
  padding: 0;
  margin: 0;
  color: #314154;
  font-weight: 500;
}
.landing_page .news-section .news-card .card-body .card-title {
  margin-left: 0.5rem;
}
.landing_page .news-section .news-card .card-body .divider {
  margin-top: 0.75rem;
  margin-bottom: 1rem;
  height: 1px;
  background-color: #DBDFE2;
  width: 100%;
}
.landing_page .news-section .news-card .card-body .news-sub-thumbnail {
  box-shadow: 0 2px 8px rgba(2, 84, 220, 0.08);
  border-radius: 0.5rem;
  width: 40px;
  height: 40px;
}
.landing_page .news-section .news-card .card-body .desc {
  max-height: 4.5rem;
}
.landing_page .news-section .news-thumbnail {
  object-fit: cover;
  height: 154px;
  border-radius: 0.25rem;
  margin-bottom: 0.75rem;
}
.landing_page .news-section .swiper-controls {
  display: none;
}
@media (min-width: 768px) {
  .landing_page .news-section .swiper-controls {
    display: block;
  }
}
.landing_page .news-section .swiper-controls .btn {
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(91, 102, 120, 0.08);
  color: #0052E1;
  top: 45%;
}
.landing_page .news-section .swiper-controls .btn-prev {
  left: -0.625rem;
}
.landing_page .news-section .swiper-controls .btn-prev:after {
  content: "\e900";
  transform: rotate(-270deg);
  font-size: 0.5rem;
}
.landing_page .news-section .swiper-controls .btn-prev.swiper-button-disabled, .landing_page .news-section .swiper-controls .btn-prev.swiper-button-disabled:disabled {
  pointer-events: none;
  background: #F4F6F8;
  color: #919EAB;
}
.landing_page .news-section .swiper-controls .btn-next {
  right: -0.625rem;
}
.landing_page .news-section .swiper-controls .btn-next:after {
  content: "\e903";
  transform: rotate(-90deg);
  font-size: 0.5rem;
}
.landing_page .news-section .swiper-controls .btn-next.swiper-button-disabled, .landing_page .news-section .swiper-controls .btn-next.swiper-button-disabled:disabled {
  pointer-events: none;
  background: #F4F6F8;
  color: #919EAB;
}
.landing_page .clients-say-section {
  position: relative;
  margin-top: 6rem;
}
.landing_page .clients-say-section .container-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("https://uprace2.vcdn.vn/web/uprace/landing/clients-say-left-bg.png"), url("https://uprace2.vcdn.vn/web/uprace/landing/clients-say-right-bg.png");
  background-position: left top, right bottom;
  background-size: contain, contain;
  background-repeat: no-repeat;
  filter: blur(50px);
}
@media (min-width: 768px) {
  .landing_page .clients-say-section .container-bg {
    filter: blur(9px);
  }
}
.landing_page .clients-say-section .container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.landing_page .clients-say-section .heading {
  color: #000000;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.5rem;
}
@media (max-width: 767px) {
  .landing_page .clients-say-section .heading {
    margin-bottom: 2.5rem;
  }
}
.landing_page .clients-say-section .clients-say-card {
  background: #F8FCFF;
  border-color: #0254DC;
}
.landing_page .clients-say-section .clients-say-card .avatar {
  width: 48px;
  height: 48px;
}
.landing_page .clients-say-section .clients-say-card .avatar-thumbnail {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: contain;
}
.landing_page .clients-say-section .clients-say-card .name {
  color: #212B36;
  font-weight: 600;
}
.landing_page .clients-say-section .clients-say-card .job-title {
  color: #212B36;
  font-size: 0.75rem;
}
.landing_page .clients-say-section .clients-say-card .divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 1px;
  background-color: #DBDFE2;
  width: 100%;
}
.landing_page .clients-say-section .clients-say-card .desc {
  max-height: 7.5rem;
}
.landing_page .clients-say-section .swiper-pagination {
  position: relative;
  display: none;
  margin-top: 2.5rem;
}
@media (min-width: 768px) {
  .landing_page .clients-say-section .swiper-pagination {
    display: flex;
    justify-content: flex-start;
    margin-left: 1.5rem;
    align-items: center;
  }
}
.landing_page .clients-say-section .swiper-pagination-bullet {
  width: 2.5rem;
  height: 0.375rem;
  border-radius: 0.5rem;
  background-color: #727F8E;
}
.landing_page .clients-say-section .swiper-pagination-bullet-active {
  background-color: #0254DC;
}
.landing_page .download-app-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.landing_page .download-app-section .left {
  padding-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .landing_page .download-app-section {
    text-align: left;
    margin-top: 7.5rem;
    flex-direction: row;
  }
  .landing_page .download-app-section .left {
    padding-bottom: 0;
    padding-right: 6rem;
  }
}
.landing_page .download-app-section .heading {
  color: #000000;
  font-size: 1.75rem;
  font-weight: 600;
}
@media (min-width: 768px) {
  .landing_page .download-app-section .heading {
    font-size: 2.5rem;
    line-height: 3.75rem;
  }
}
.landing_page .download-app-section .desc {
  color: #314154;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.landing_page .download-app-section .qr-code {
  width: 96px;
  height: 96px;
}
.landing_page .download-app-section .illustration-thumbnail {
  width: 400px;
  height: 350px;
}

.download-now-scroll {
  position: fixed;
  bottom: 3rem;
  right: 1.25rem;
  z-index: 1025;
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
}
.download-now-scroll .download-now__link {
  position: absolute;
  text-align: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;
  background: #0254DC;
  box-shadow: 0 2px 20px rgba(0, 33, 88, 0.12);
  text-decoration: none;
  font-size: 10px;
  line-height: 1.125rem;
  font-weight: 700;
  padding-top: 20px;
}
.download-now-scroll .download-now__link .fi-download:after {
  content: "\e908";
}
.download-now-scroll .pulse-button {
  position: relative;
  cursor: pointer;
  transform: scale(1);
  -webkit-animation: pulse-download 1s infinite;
  -moz-animation: pulse-download 1s infinite;
  -ms-animation: pulse-download 1s infinite;
  animation: pulse-download 2s infinite;
}
@keyframes pulse-download {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(2, 84, 220, 0.2);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(2, 84, 220, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(2, 84, 220, 0);
  }
}

/*# sourceMappingURL=landing.css.map */
