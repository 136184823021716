.ranking-name {
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    width: 100px;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    margin-bottom: 2px;
}
